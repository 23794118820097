<template lang="pug">
section.my-4
  b-container
    b-row.justify-content-center
      b-col(v-if="limitUpload === 0")
        .text-center.my-5
          b-spinner(variant="okcarpremium" label="Spinning")
          p.text-okcarpremium Cargado espere un momento...
      b-col(v-else-if="limitUpload === 1")
        b-card(header="NUEVO VEHICULOS", header-tag="header")
          h5.text-uppercase.text-center.text-purple
            | ¿Qué Vehículo Quieres Publicar?
          p.text-muted.text-center
            | En {{ $appName }} podras publicar (casi) cualquier vehículo
          validation-observer(ref="observer", v-slot="{ passes }")
            b-form(@submit.stop.prevent="passes(onSubmit)")
              b-row
                b-col.text-center.mb-2(cols="12")
                  radio-button(
                    name="state",
                    v-model="vehicle.state",
                    :options="states",
                    @changeValue="vehicle.state = $event"
                  )
              hr
              b-row
                b-col(cols="12")
                  h5.pb-4 Datos del vehículo
                b-col(cols="12", md="6")
                  validation-provider(name="Marca" rules="required" v-slot="validationContext")
                    b-form-group(label="Marca")
                      b-form-select(
                        placeholder="Seleccionar",
                        v-model.lazy="vehicle.brand",
                        :options="brands",
                        value-field="id",
                        text-field="name",
                        :disabled="vehicle.state ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-brand"
                      )
                      b-form-invalid-feedback#input-brand {{ validationContext.errors[0] }}
                b-col(cols="12", md="6")
                  validation-provider(name="Modelo" rules="required" v-slot="validationContext")
                    b-form-group(label="Modelo")
                      b-form-select(
                        placeholder="Seleccionar",
                        v-model="vehicle.model",
                        :options="models",
                        value-field="id",
                        text-field="name",
                        :disabled="vehicle.brand ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-model"
                      )
                      b-form-invalid-feedback#input-model {{ validationContext.errors[0] }}
                b-col(cols="12", md="3")
                  validation-provider(name="Versión" rules="required|min:3" v-slot="validationContext")
                    b-form-group(label="Versión")
                      b-form-input(
                        v-model="vehicle.version",
                        :disabled="vehicle.model ? false : true",
                        :state="getValidationState(validationContext)",
                        type="text",
                        aria-describedby="input-version"
                      )
                      b-form-invalid-feedback#input-version {{ validationContext.errors[0] }}
                b-col(cols="12", md="3")
                  validation-provider(name="Carroceria" rules="required" v-slot="validationContext")
                    b-form-group(label="Carroceria")
                      b-form-select(
                        placeholder="Seleccionar",
                        v-model="vehicle.bodywork",
                        :options="bodyworks",
                        value-field="id",
                        text-field="name",
                        :disabled="vehicle.version ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-bodywork"
                      )
                      b-form-invalid-feedback#input-bodywork {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Año" rules="required|min_value:1960|max_value:2024" v-slot="validationContext")
                    b-form-group(label="Año")
                      b-form-input(
                        v-model="vehicle.year",
                        type="number",
                        min="1960",
                        max="2024",
                        step="1",
                        :disabled="vehicle.bodywork ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-year"
                      )
                      b-form-invalid-feedback#input-year {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Cilindrada" rules="required" v-slot="validationContext")
                    b-form-group(label="Cilindrada")
                      b-form-input(
                        v-model="vehicle.displacement",
                        type="number",
                        min="0.1",
                        step="0.1",
                        :disabled="vehicle.year ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-displacement"
                      )
                      b-form-invalid-feedback#input-displacement {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Kilómetraje" rules="required|numeric" v-slot="validationContext")
                    b-form-group(label="Kilómetraje")
                      b-form-input(
                        v-model="vehicle.mileage",
                        type="number",
                        min="0",
                        step="0.1",
                        :disabled="vehicle.displacement ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-mileage"
                      )
                      b-form-invalid-feedback#input-mileage {{ validationContext.errors[0] }}
                b-col(cols="12" lg="3" md="6")
                  validation-provider(name="Transmisión" rules="required" v-slot="validationContext")
                    b-form-group(label="Transmisión")
                      radio-button-icon(
                        name="transmission",
                        v-model="vehicle.transmission",
                        :options="transmissions",
                        @handleInput="vehicle.transmission = $event",
                        :disabled="vehicle.displacement ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-mileage"
                      )
                      b-form-invalid-feedback#input-mileage {{ validationContext.errors[0] }}
                b-col(cols="12" lg="4" md="6")
                  validation-provider(name="Tracción" rules="required" v-slot="validationContext")
                    b-form-group(label="Tracción")
                      radio-button-icon(
                        name="traction",
                        v-model="vehicle.traction",
                        :options="tractions",
                        @handleInput="vehicle.traction = $event",
                        :disabled="vehicle.transmission ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-traction"
                      )
                      b-form-invalid-feedback#input-traction {{ validationContext.errors[0] }}
                b-col(cols="12" lg="5" md="12")
                  validation-provider(name="Combustible" rules="required" v-slot="validationContext")
                    b-form-group(label="Combustible")
                      radio-button-icon(
                        name="fuel",
                        v-model="vehicle.fuel",
                        :options="fuels",
                        @handleInput="vehicle.fuel = $event",
                        :disabled="vehicle.traction ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-fuel"
                      )
                      b-form-invalid-feedback#input-fuel {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Nº Asientos" rules="required|numeric", v-slot="validationContext")
                    b-form-group(label="Nº Asientos")
                      b-form-input(
                        v-model="vehicle.number_seats",
                        type="number",
                        min="1",
                        step="1",
                        :disabled="vehicle.fuel ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-seats"
                      )
                      b-form-invalid-feedback#input-seats {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Nº Puertas" rules="required|numeric" v-slot="validationContext")
                    b-form-group(label="Nº Puertas")
                      b-form-input(
                        v-model="vehicle.number_doors",
                        type="number",
                        min="1",
                        step="1",
                        :disabled="vehicle.number_seats > 0 ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-doors"
                      )
                      b-form-invalid-feedback#input-doors {{ validationContext.errors[0] }}
                b-col(cols="12", md="2")
                  validation-provider(name="Nº Dueños" rules="required|numeric" v-slot="validationContext")
                    b-form-group(label="Nº Dueños")
                      b-form-input(
                        v-model="vehicle.owners"
                        type="number",
                        min="1",
                        step="1",
                        :disabled="vehicle.number_doors ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-owners"
                      )
                      b-form-invalid-feedback#input-doors {{ validationContext.errors[0] }}
                b-col(cols="12", md="3")
                  validation-provider(name="Precio", rules="required", v-slot="validationContext")
                    b-form-group(label="Precio")
                      b-form-input(
                        v-model.lazy="vehicle.price",
                        v-money="vehicle.price !== null ? money : null"
                        type="text",
                        :disabled="vehicle.owners ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="input-price"
                      )
                      b-form-invalid-feedback#input-price {{ validationContext.errors[0] }}
                b-col(cols="12", md="3")
                  b-form-group(label="Precio con crédito")
                    b-form-input(
                      v-model.lazy="vehicle.funded_price",
                      v-money="vehicle.funded_price !== null ? money : null"
                      type="text",
                      placeholder="Opcional",
                      :disabled="vehicle.price ? false : true",
                      aria-describedby="input-financed-price"
                    )
                b-col(cols="12")
                  validation-provider(name="Descripción" rules="required" v-slot="validationContext")
                    b-form-group(label="Descripción")
                      b-form-textarea(
                        v-model="vehicle.description",
                        placeholder="Cuéntanos más acerca de tu vehículo",
                        rows="5",
                        :disabled="vehicle.price ? false : true",
                        :state="getValidationState(validationContext)",
                        aria-describedby="textarea-description"
                      )
                      b-form-invalid-feedback#textarea-description {{ validationContext.errors[0] }}
                b-col(cols="12")
                  b-form-group#group-accessories(label="Accesorios")
                    b-form-checkbox-group(
                      switches
                      size="sm"
                      v-model="accessories_selected"
                      :options="accessories"
                      value-field="id"
                      text-field="name"
                      name="accesorios"
                      id="checkbox-accessories"
                      :disabled="vehicle.description ? false : true"
                    )
                b-col(cols="12")
                  h5.mt-3 Video del vehículo:
                  b-form-group#group-video.mb-1
                    b-form-input(
                      type="text"
                      name="video"
                      v-model="vehicle.video"
                      placeholder="Ejemplo: Ci8yyl0JQTQ"
                      maxlength="11"
                      size="11"
                      :disabled="accessories_selected.length > 0 ? false : true"
                    )
                  small.ml-3.mb-3
                    span.text-muted  https://www.youtube.com/watch?v=
                    span Ci8yyl0JQTQ
                b-col(cols="12")
                  h5.mt-3 Imagenes del vehículo
                  b-overlay#loading(:show="showUpload" opacity="0.7" no-wrap)
                    template(v-slot:overlay="")
                      .text-center
                        b-spinner(variant="info")
                        span.d-block.text-info Espere un momento...
                  #images-wrapper
                    draggable#images(v-model="vehicle.images" group="images" ghost-class="ghost" @end="updateOrder")
                      .image-wrapper(v-for="(image, key) in vehicle.images" v-bind:key="`${rndStr(3)}-${key}`")
                        label.image(draggable="true")
                          a.image-trash(@click="destroyImage(key,image)")
                            font-awesome-icon(icon="times")
                          b-img-lazy(v-bind="mainProps" alt="Rounded image" :src="image.thumbnail")
                    input#images-upload(
                      type="file"
                      ref="file"
                      name="files[]"
                      multiple=""
                      accept="image/png, image/jpeg, image/gif"
                      @change="changeImage"
                      :disabled="accessories_selected.length > 0 ? false : true"
                    )
                    .image-upload(@click="$refs.file.click()")
                      label.image.placeholder
                        i.icon-camera.fa-4x
                b-col(cols="12")
                  b-progress(
                    v-if="images.length > 0"
                    :max="max"
                    variant="okcarpremium mb-2"
                    height="2rem"
                    striped
                    :animated="true"
                  )
                    b-progress-bar(:value="value" :label="`${((value / max) * 100).toFixed(0)}%`")
                b-col(cols="12")
                  b-button(type="submit" :disabled="butonDisabled" block variant="outline-okcarpremium") {{ buttonText }}
              b-overlay#loading(:show="show", opacity="0.7", no-wrap="")
                template(v-slot:overlay="")
                  .text-center
                    b-spinner(variant="info")
                    span.d-block.text-info Espere un momento...
      b-col(v-else).mb-5
        professional-plans
  b-modal#modal-created-seller(title="Nuevo cliente", hide-footer)
    form-create-seller(@createSeller="sellerCreated = $event")
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import Multiselect from "vue-multiselect"
import draggable from 'vuedraggable'
import RadioButton from '@/components/product/RadioButton'
import RadioButtonIcon from '@/components/product/RadioButtonIcon'
import FormCreateSeller from '@/components/seller/FormCreate'
import ProfessionalPlans from '@/components/professional/Plans'

export default {
  name: "FormCreateVehicle",
  components: {
    draggable,
    RadioButton,
    RadioButtonIcon,
    FormCreateSeller,
    ProfessionalPlans,
    Multiselect,
  },
  directives: {
    mask,
    money: VMoney,
  },
  data() {
    return {
      customMask: {
        mask: "AA-FF-FF",
        tokens: {
          A: {
            pattern: /[a-zA-Z]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
          F: {
            pattern: /[0-9a-zA-Z]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
        },
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      states: [
        { name: "Nuevo", value: "nuevo", icon: "icon-home" },
        { name: "Usado", value: "usado", icon: "" },
      ],
      transmissions: [
        { name: "Automático", value: "automático", icon: "icon-automatic" },
        { name: "Manual", value: "manual", icon: "icon-mechanic" },
      ],
      tractions: [
        { name: "4X2", value: "4x2", icon: "icon-x2" },
        { name: "4X4", value: "4x4", icon: "icon-x4" },
        { name: "AWD", value: "awd", icon: "icon-awd" },
      ],
      fuels: [
        { name: "Bencina", value: "bencina", icon: "icon-gasoline" },
        { name: "Diesel", value: "diesel", icon: "icon-petroleum" },
        { name: "Electrico", value: "electrico", icon: "icon-electric" },
        { name: "Gas", value: "gas", icon: "icon-gas" },
        { name: "Hibrido", value: "hibrido", icon: "icon-hibrido" },
      ],
      product_id: "",
      butonDisabled: false,
      buttonText: "Publicar Vehículo",
      sellers: [{ id: 0, name: "Seleccionar..." }],
      communes: [{ id: 0, name: "Seleccionar..." }],
      brands: [{ id: 0, name: "Seleccionar..." }],
      models: [{ id: 0, name: "Seleccionar..." }],
      payment_types: [
        { id: 0, name: "Seleccionar..." },
        { id: 1, name: "Contado" },
        { id: 2, name: "Crédito" },
      ],
      vehicle: {
        seller_id: 0,
        state: null,
        takeover_price: null,
        payment_type: 2,
        brand: null,
        model: null,
        bodywork: null,
        accessories: null,
        price: null,
        funded_price: null,
        year: "",
        version: "",
        number_seats: null,
        number_doors: null,
        displacement: "",
        mileage: "",
        transmission: "",
        traction: "",
        fuel: "",
        description: "",
        key_copies: 0,
        country: 1,
        region: 4,
        commune: 33,
        category_id: 1,
        owners: 0,
        label_name: null,
        label_color: null,
        video: null,
        images: [],
      },
      mainProps: {
        center: true,
        blankColor: "#bbb",
      },
      selectedFile: null,
      image: null,
      images: [],
      imagesBase64: [],
      maxUpload: 0,
      maxImage: this.$store.state.image.maxImages,
      show: false,
      showUpload: false,
      max: 0,
      step: 0,
      value: 0,
      sellerCreated: null,
      stock: 0,
      productMax: 7,
      limitUpload: 0,
      accessories_selected: [],
    };
  },
  created() {
    window.onbeforeunload = () => {
      return "¿Seguro que quieres salir?";
    };
  },
  mounted() {
    this.checkLimit();
    this.getSellers();
    this.pushBtnUpload();
    this.getBrandSelect();
    this.getBodyworks();
    this.getRegions();
    this.shiftBodywork();
    this.getAccessories()
  },
  computed: {
    ...mapState("auth", ["isAdmin", "isProfessional"]),
    ...mapState("bodywork", ["bodyworks"]),
    ...mapState("region", ["regions"]),
    ...mapState("accessory", ["accessories"]),
    ...mapState("image", ["images_store"]),
    ...mapGetters("accessory", ["getAccessoryById"])
  },
  methods: {
    ...mapActions("bodywork", ["getBodyworks","shiftBodywork"]),
    ...mapActions("accessory", ["getAccessories"]),
    ...mapActions("region", ["getRegions"]),
    ...mapMutations("image", ["SET_IMAGES"]),
    updateOrder() {
      this.vehicle.images.forEach((image, index) => {
        image.order = index + 1;
      });
    },
    async checkLimit() {
      const {
        data: { stock },
      } = await this.$axios.get("/api/v1/products/stock");
      this.stock = stock;
      if (this.stock <= this.productMax || this.isAdmin || this.isProfessional) {
        this.limitUpload = 1;
      } else {
        this.limitUpload = 2;
      }
    },
    async getSellers() {
      try {
        const {
          data: { data },
        } = await this.$axios.get("/api/v1/seller");
        this.sellers = data;
      } catch (error) {
        console.error(error);
      }
    },
    async uploadImage(image_new) {
      try {
        const {
          data: { image },
        } = await this.$axios.post("/api/v1/image", image_new);
        this.value += this.step;
        this.vehicle.images.push({
          image: image.url,
          thumbnail: image.urlThumbnail,
          publicId: image.publicId,
        });
        if (parseInt(this.value, 10) == parseInt(this.max, 10)) {
          setTimeout(() => {
            this.showUpload = false;
            this.images = [];
            this.imagesBase64 = [];
            this.max = 0;
            this.step = 0;
            this.value = 0;
          }, 1000);
        }
      } catch (e) {
        this.value += this.step;
        this.showUpload = false;
        if (parseInt(this.value, 10) == parseInt(this.max, 10)) {
          setTimeout(() => {
            this.showUpload = false;
            this.images = [];
            this.imagesBase64 = [];
            this.max = 0;
            this.step = 0;
            this.value = 0;
          }, 1000);
        }
        this.$bvToast.toast(e.response.data.message, {
          title: "Error al subir imagen",
          variant: "danger",
          solid: true,
        });
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getBrandSelect() {
      try {
        const { data } = await this.$axios.get("/api/v1/brands");
        this.brands = data.data;
        this.vehicle.brand = null;
        this.brands.unshift({ id: null, name: "Seleccionar..." });
      } catch (error) {
        console.error(error);
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("product_id", this.product_id);
    },
    uploadSuccess() {
      this.vehicle.brand = [];
      this.vehicle.model = [];
      this.vehicle.price = "";
      this.vehicle.funded_price = "";
      this.vehicle.year = "";
      this.vehicle.version = "";
      this.vehicle.number_doors = "";
      this.vehicle.number_seats = "";
      this.vehicle.displacement = "";
      this.vehicle.bodywork = "";
      this.vehicle.mileage = "";
      this.vehicle.transmission = "";
      this.vehicle.traction = "";
      this.vehicle.fuel = "";
      this.vehicle.key_copies = 0;
      this.vehicle.description = "";
      this.vehicle.region = [];
      this.vehicle.commune = [];
      this.vehicle.video = "";
      this.vehicle.images = [];
      this.vehicle.owners = 0;
      this.butonDisabled = false;
      this.buttonText = "Publicar Vehículo";
      this.$router.push("/dashboard");
    },
    pushBtnUpload() {
      this.maxUpload = this.maxImage - this.vehicle.images.length;
    },
    readAndPreview(file) {
      if (!/\.(jpe?g|png|gif|heic|jfif)$/i.test(file.name)) {
        return alert(file.name + " Imagen no soportada");
      }
      let reader = new FileReader();
      reader.addEventListener("load", this.setImg, false);
      reader.readAsDataURL(file);
    },
    setImg(e) {
      this.imagesBase64.push({
        image: e.target.result,
      });
    },
    rndStr(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    isBase64(str) {
      return str.length > 28;
    },
    changeImage(e) {
      this.images = e.target.files;
      this.showUpload = true;
      if (this.images.length <= this.maxUpload) {
        if (this.images) [].forEach.call(this.images, this.readAndPreview);
        this.$emit("input", this.images);
        //this.showUpload = false;
      } else {
        this.$swal(
          "Maximo de images",
          `No es posible subir mas de ${this.maxUpload} imagenes`,
          "error"
        );
        this.showUpload = false;
        this.images = [];
        this.imagesBase64 = [];
        this.max = 0;
        this.step = 0;
        this.value = 0;
      }
    },
    destroyImage(key, image) {
      this.$swal({
        title: "Eliminar Imagen",
        text: "Estas seguro(a) de eliminar esta imagen",
        icon: "warning",
        confirmButtonClass: "btn btn-outline-danger",
        cancelButtonClass: "btn btn-outline-secondary ml-2",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.showUpload = true;
          try {
            this.$axios
              .post("/api/v1/image/destroy", image)
              .then(() => {
                this.$delete(this.vehicle.images, key);
                this.showUpload = false;
              })
              .catch((error) => {
                console.log(error);
                this.showUpload = false;
              });
          } catch (e) {
            console.error(e);
            this.showUpload = false;
          }
        }
      });
    },
    ShowCreatedSeller() {
      this.$bvModal.show("modal-created-seller");
    },
    onSubmit() {
      this.loading();
      if (this.$invalid) {
        this.$bvToast.toast("Campos requeridos o Inválidos en el formulario.", {
          title: "Campos requeridos",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
        });
      } else {
        if (this.vehicle.images.length > 0) {
          this.butonDisabled = true;
          this.buttonText = "Cargando...";
          let accessoriesArray = [];
          if (this.accessories_selected.length > 0) {
            this.accessories_selected = this.accessories_selected
              .slice()
              .sort((a, b) => a - b);
            this.accessories_selected.forEach((id) =>
              accessoriesArray.push(this.getAccessoryById(id))
            );
          }
          this.vehicle.accessories = JSON.stringify(accessoriesArray);
          this.$axios
            .post("/api/v1/product", this.vehicle)
            .then((res) => {
              this.product_id = res.data.product_id;
              this.show = false;
              this.$swal({
                title: "Registro de vehículo realizado con éxito.",
                text: "Producto registrado",
                icon: "success",
                confirmButtonClass: "btn btn-outline-primary",
                confirmButtonText: "Ok",
                buttonsStyling: false,
              });
              this.uploadSuccess();
            })
            .catch((error) => {
              this.butonDisabled = false;
              this.buttonText = "Publicar Vehículo";
              this.show = false;
              this.$swal({
                title: "Registro de vehículo!",
                text: "Error al realizar el registro del vehículo.",
                icon: "error",
                confirmButtonClass: "btn btn-outline-danger",
                confirmButtonText: "Ok",
                buttonsStyling: false,
              });
              console.error(error);
            });
        } else {
          this.butonDisabled = false;
          this.buttonText = "Publicar Vehículo";
          this.$swal({
            title: "Imagen requerida!",
            text: "Como minimo debe cargar una imagen válida",
            icon: "error",
            confirmButtonClass: "btn btn-outline-danger",
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
        }
      }
    },
    loading() {
      this.show = true;
      const height = window.outerHeight;
      window.scrollTo({ top: height / 2 });
    },
  },
  watch: {
    "vehicle.region": async function (id) {
      if (id) {
        try {
          const { data } = await this.$axios.get(`/api/v1/communes/${id}`);
          this.communes = data.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.communes = [{ id: null, name: "Seleccionar..." }];
      }
    },
    "vehicle.brand": async function (id) {
      if (id) {
        try {
          this.models = [];
          const { data } = await this.$axios.get(`/api/v1/models/${id}`);
          this.models = data.data;
          this.vehicle.model = null;
          this.models.unshift({ id: null, name: "Seleccionar..." });
        } catch (error) {
          console.error(error);
        }
      } else {
        this.models = [{ id: null, name: "Seleccionar..." }];
      }
    },
    "vehicle.images": function (images) {
      this.SET_IMAGES(images);
      this.pushBtnUpload();
    },
    imagesBase64: function () {
      const arrayImages = JSON.parse(JSON.stringify(this.imagesBase64));
      if (this.images.length === arrayImages.length) {
        this.max = 100;
        this.step = 100 / this.images.length;
        arrayImages.forEach((image) => this.uploadImage(image));
      }
    },
    sellerCreated: function (seller) {
      this.sellers.push(seller);
      this.vehicle.seller_id = seller.id;
      this.$bvModal.hide("modal-created-seller");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/upload-images";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
.custom-select {
  border-radius: 20px;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}

.multiselect {
  &__tags {
    border-radius: 20px !important;
  }
  &__content-wrapper {
    border-radius: 20px;
    margin: 3px auto;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 8px;
  }
}

.bg-okcarpremium {
  background-color: $primary;
}

#checkbox-accessories {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .payment_type {
    legend {
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
